import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/banks',
    name: 'banks',
    component: () => import('../views/Banks.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/providers',
    name: 'providers',
    component: () => import('../views/Providers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/Categories.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/drivers',
    name: 'drivers',
    component: () => import('../views/Drivers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dryers',
    name: 'dryers',
    component: () => import('../views/Dryers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dried',
    name: 'dried',
    component: () => import('../views/Dried.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/weight_notes',
    name: 'weight_notes',
    component: () => import('../views/WeightNotes.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ground',
    name: 'ground',
    component: () => import('../views/Ground.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/expenses',
    name: 'expenses',
    component: () => import('../views/Expenses.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/deposits',
    name: 'deposits',
    component: () => import('../views/Deposits.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reception',
    name: 'reception',
    component: () => import('../views/Reception.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/pays',
    name: 'pays',
    component: () => import('../views/Pays.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/Sales.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/advances',
    name: 'advances',
    component: () => import('../views/Advances.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/calculate',
    name: 'calculate',
    component: () => import('../views/Calculate.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/constance',
    name: 'constance',
    component: () => import('../views/Constance.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: () => import('../views/Vouchers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/calculatePartner',
    name: 'calculatePartner',
    component: () => import('../views/CalculatePartner.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reportRetentions',
    name: 'reportRetentions',
    component: () => import('../views/ReportRetentions.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reportExpenses',
    name: 'reportExpenses',
    component: () => import('../views/ReportExpenses.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reportTransferences',
    name: 'reportTransferences',
    component: () => import('../views/ReportTransferences.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/completePays',
    name: 'completePays',
    component: () => import('../views/CompletePays.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ingreso',
    name: 'ingreso',
    component: () => import('../views/Ingreso.vue'),
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('../views/ResetPassword.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async(to, from, next) => {
  
  const ruta_protegida = to.matched.some(record => record.meta.requiresAuth);
  const user = localStorage.usuario;
  
  
  if (ruta_protegida === true && (user === null || user == undefined)) {
    next({path: '/ingreso'})
  }
  else {
    try {
      const permisos = JSON.parse(localStorage.permisos);
      const screen = to.name;
      const master = localStorage.master;
      if(permisos[screen] == '0' && master == '0') {
        // next({path: '/ingreso'})
      }
    }
    catch { }
    next();
  }
})

export default router
